import React, { useEffect, useState } from 'react';
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import Header from '../directives/header';
import Swal from 'sweetalert2';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import { gettemproductAction,getOrdersDetailsAction,removeOrderProductAction,updateOrderProductQtyAction } from '../Action/action';
import Cookies from "js-cookie";
const loginData = (!Cookies.get('loginSuccessfastenerwarehouseAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessfastenerwarehouseAdmin'));
 const Editorder = () => {
	 if (!loginData || loginData == '') {
		window.location.href = `${config.baseUrl}`;
	}
	if(loginData.role != config.role_admin){
		window.location.href = `dashboard`;	
	}
    const {order_id} = useParams();
    //~ const [ordersForm, setorderdetails] = useState({});
 
    const [ordersForm, setOrdersForm] = useState({
        order_id: order_id,
        customerDetail: [], 
        products: []
    });
    const [validationError, setValidationError] = useState({});
    const [searchProduct, setsearchProduct] = useState([]);
    const [spinloader, setspinloader] = useState('0');
    useEffect(() => {
        getOrdersDetailsApi()
     }, []);

    const handleKeyPress = (e) => {
        const isValidInput = /^[0-9\b]+$/.test(e.key);
        if (!isValidInput) {
            e.preventDefault();
        }
    };
    
   const handlePriceKeyPress = (e) => {
		const isValidInput = /^[0-9.\b]$/.test(e.key);
		if (!isValidInput) {
		  e.preventDefault();
		}
	  };


    const inputHandler = (e) => {
        const { name, value } = e.target;
        setOrdersForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    };
    
    const handleProductSelect = (row) => {  
        const { description, sell_quantity_minimum, id, price_per, hobson_part, sell_quantity_increment } = row;
        
        Swal.fire({
            title: '',
            text: "Are you sure you want to add this product?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
        }).then(async (result) => {
           
           if (result.isConfirmed) {
				let res = await updateOrderProductQtyAction({ user_id:ordersForm.customerDetail.id, product_id:id, order_id:order_id, update_type:"inc" });
				if (res.success) {
					toast.success(res.msg)
					setOrdersForm(res.data); 
				} else {
					toast.error(res.msg); 
				}
			}
            
        });
    
        setsearchProduct([]);
    };
    
    

	const getOrdersDetailsApi = async (order) => {
  
		let res = await getOrdersDetailsAction({order_id:order_id});
		if (res.success) {
			let data = res.data
			
			setOrdersForm(data);
			
			
			//~ setorderdetails(data);
      
		}else{
			setOrdersForm({});
		}
	};


    

    const searchtempproduct = async (str) => {
        if (str !== '') {
            const res = await gettemproductAction({ str });
            if (res.success) {
                setsearchProduct(res.data);
               
            } else {
                setsearchProduct([]);
            }
        } else {
            setsearchProduct([]);
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!ordersForm.products.length) errors.ordersProductsError = 'Please select at least one product.';

        setValidationError(errors);
        return Object.keys(errors).length <= 0;
    };

   
// update order detail
    const submitForm = async (e) => {      
        e.preventDefault();
        console.log(ordersForm)
        const isValid = validateForm();
		if (!isValid) {
		}
        else{
			setspinloader(1);
            let res = await updateOrderProductQtyAction({...ordersForm,order_id:order_id})
            if(res.success){
                toast.success(res.msg)
               setTimeout(() => {
					window.location.reload();
				}, 1200);
            }
            else{
				setspinloader(0);
                toast.error(res.msg)
            }
        }
    };

      
    const handleQuantityChangeManual = async (e, row,action) => {
        
       let product_id = row.product_id;
       
       let res = await updateOrderProductQtyAction({ user_id:ordersForm.customerDetail.id, product_id:product_id, order_id:order_id, update_type:action });
		if (res.success) {
			toast.success(res.msg)
			setOrdersForm(res.data); 
			
		} else {
			toast.error(res.msg); 
		}
       
    };
    
   
     
    
    const CartRemoveProduct = async (order_id, product_id) => {
        console.log(order_id, product_id);
        Swal.fire({
            title: '',
            text: "Are you sure you want to remove this product?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
        }).then(async (result) => {
           
           if (result.isConfirmed) {
				let res = await removeOrderProductAction({ user_id:ordersForm.customerDetail.id, product_id:product_id, order_id:order_id });
				if (res.success) {
					toast.success(res.msg)
					setOrdersForm(res.data); 
				} else {
					toast.error(res.msg); 
				}
			}
            
        });
    };
   
    return (
        <>
            <Sidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <Toaster />
                <Header />
                <div className="container-lg mb-4">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb my-0 ms-2">
                            <li className="breadcrumb-item">
                                <Link className="homelink" to={`${config.baseUrl}`}>Home</Link>
                            </li>
                            <li className="breadcrumb-item active"><span>Edit order</span></li>
                        </ol>
                    </nav>
                </div>
                <div className="body flex-grow-1 px-3">
                    <div className="container-lg">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col-md-6 text-start">
                                                <span>Order #{ordersForm.quote_code}</span>
                                            </div>
                                            <div className="col-md-6 text-end">
                                                <Link className="btn btn-primary editbtn text-white" to={`${config.baseUrl}orders`}>Back</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body inner-body">
                                        <form className="row g-3">
                                                                                  
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="ordersProducts">Search product <span className="req-star">*</span></label>
                                                <input
                                                    className={validationError.ordersProductsError ? 'form-control is-invalid' : 'form-control'}
                                                    name="ordersProducts"
                                                    placeholder="Search product"
                                                    type="text"
                                                    onChange={(e) => searchtempproduct(e.target.value)}
                                                    autoComplete="off"
                                                />
                                                {searchProduct.length > 0 && (
                                                    <ul className="suburbList">
                                                        {searchProduct.map((row) => (
                                                            <li key={row.id} onClick={() => handleProductSelect(row)}>
                                                                {row.hobson_part}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                                <div className="invalid-feedback">{validationError.ordersProductsError}</div>
                                            </div>
                                            {ordersForm.products.length>0 && (
                                                <div className="row" id="tempproducts">
                                                    <table className="table mb-0">
                                                        <thead className="table-light fw-semibold">
                                                            <tr className="align-middle table-heading">
                                                                <th>Product</th>
                                                                <th>Price</th>								
																<th>Total</th>	
                                                                <th style={{textAlign:"center"}}>Quantity</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ordersForm.products.map((row, index) => (
                                                                <tr key={index}>
                                                                    <td className="first_product">{row.description}<br></br> <br></br><span class="first_product-sku">{row.hobson_part}</span></td>
                                                                    <td>{parseFloat(row.price).toFixed(4)}</td>
                                                                    <td className="item_price">
																		${(row.quantity * row.price).toFixed(2)}
																	  </td>		
                                                                   
                                                                    <td style={{textAlign:"center"}}>
																<div className="quntity-box d-flex gap-3 items-center ">
																	<div className="value-button mt-1 csr-ptr" id="decrease" onClick={(e)=>handleQuantityChangeManual(e,row,'dec')}  >-</div>
																	<input type="text" className="form-control" style={{height:"25px",width:"80px",textAlign:"center"}} value={row.quantity}  disabled />
																	<div className="csr-ptr mt-1 " id="increase" onClick={(e)=>handleQuantityChangeManual(e,row,'inc')}  >+</div>
																</div>
																</td>
                                                                    <td>
                                                                        <span className="csr-ptr" onClick={() => CartRemoveProduct(row.order_id, row.product_id)}>x</span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        <tfoot>
														<tr className="valignTOP">
															<td align="left" colspan="2">
																
																<h4>Shipping details</h4>
																<p>
																	{ordersForm.name}<br />{ordersForm.company}<br />{ordersForm.address_1}<br />{ordersForm.city} {ordersForm.state} {ordersForm.postcode}<br />AU	</p>
																<p><strong>Mobile: </strong>{ordersForm.phone}</p>
																<p><strong>Email: </strong>{ordersForm.email}</p>
																		</td>
															<td align="right" colspan="4">
																			
																<h4>Payment Details</h4>
																<p>Order subtotal: ${ordersForm.sub_total} <br />
																{ordersForm.order_discount > 0 ? (
																	<>
																	Credit Note Discount:  ${ordersForm.order_discount}<br />
																	</>
																):null }					
																 
																 Order Shipping: ${ordersForm.shipping} <br />
																 Order GST: ${ordersForm.tax}<br />
																 Order Total: ${ordersForm.total}</p>
																<p>PAYMENT METHOD : {ordersForm.payment_method=='COD' ? '30 Day EOM Credit Account' : 'PAID SECURELY USING YOUR CREDIT CARD'} <br /> 
																{ordersForm.payment_method != 'COD' ? (
																	<>
																	TXN ID: {ordersForm.txn_id}
																	</>
																	):null
																}
																</p>
																 
																 
															</td>
														</tr>
													</tfoot>
                                                    </table>
                                                </div>
                                            )}
                                            
                                           
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Editorder
