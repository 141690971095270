import React, { useEffect, useState } from 'react';
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { searchorderproductAction, getAllUserslistAction, selecteduseraddressAction, calculateorderShippingAction, CreateUserOrderQuoteAction, AddtoquoteCartAction, getquoteOrderTotalAction,PlaceCODOrderAction, removecartProductAction, updatecartqytAction, UpdateCartProductQtyAction , UpdateCartProductPriceAction , UpdateCartCustomFreightAction
	
	, deleteUseraddressAction, AddUserNewAddressAction, updateShipToAddressAction,UpdateAddressAction
	
	
	 } from '../Action/action';
import Cookies from "js-cookie";
const loginData = (!Cookies.get('loginSuccessfastenerwarehouseAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessfastenerwarehouseAdmin'));
const Createorder = () => {
	if (!loginData || loginData == '') {
		window.location.href = `${config.baseUrl}`;
	}
	if(loginData.role != config.role_admin){
		window.location.href = `dashboard`;	
	}
    const [orderForm, setOrderForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company_name: '',
        orderProducts: []
    });
    const [quotecode, SetQuotecode] = useState('')
    const [quote_id, Setquote_id] = useState('')
    const [validationError, setValidationError] = useState({});
    const [users, setUsers] = useState([]);  
    const [searchProduct, setSearchProduct] = useState([]);
    const [orderProducts, setorderProducts] = useState([])
    const [defaultAddress, setDefaultAddress] = useState({});
    const [defaultAddress2, setDefaultAddress2] = useState({});
    const [showNewAddress, setshowNewAddress] = useState(1);
    const [UserAddress, setUserAddress] = useState([]);
    const [siteDeliveryAdd, setsiteDeliveryAdd] = useState({});
    const [totals, setTotals] = useState({});
    
    const [newAddressform, setnewAddressForm] 	= useState({ user_id:orderForm.user_id,first_name:'',last_name:'',phone:'', newaddress_1: '', newstate: '', newpostcode: '', newsuburb:'', shipto:1 });

    const [showAddressEditForm, setshowAddressEditForm] = useState(0);
    
   const [addsuburb, setaddsuburb] 							= useState('');
	const [addpostcode, setaddpostcode] 						= useState('');
	const [addeditsuburb, setaddeditsuburb] 					= useState('');
	const [addeditpostcode, setaddeditpostcode] 				= useState('');
	
	 const [addmodalopen, setAddModalopen] 						= useState(0);
	 
	 
	 const [is_custom_freight, set_is_custom_freight] 			= useState(0);
	 
	 
	 
	 const updateShipToAddress = async (userAdd) => {
		let res = await updateShipToAddressAction({address_id:userAdd.id,user_id:orderForm.user_id,shipto:showNewAddress});
		if (res.success) {	
			getselecteduserAddressApi(orderForm.user_id);
			setAddModalopen(0);  					
		}else{
			toast.error(res.msg);	
		}
			
	}  	
	

    useEffect(() => {
        searchUsersApi();
    }, []);
    
    useEffect(() => {    
		const defaultAddr 	= UserAddress.find(address => address.shipto === 0 && address.set_default === 1);
		const defaultAddr2 	= UserAddress.find(address => address.shipto === 1 && address.set_default === 1);
		const siteAddress 	= UserAddress.find(address => address.shipto === 2 && address.set_default === 1);
		if (defaultAddr) {
		  setDefaultAddress(defaultAddr);
		}
		if (defaultAddr2) {
		  setDefaultAddress2(defaultAddr2);
		}
		if (siteAddress) {
		  setsiteDeliveryAdd(siteAddress);
		}
		updateordersummery(quote_id);		
	  }, [UserAddress]);

      useEffect(() => { 
		updateordersummery(quote_id);
	  }, [showNewAddress]);  
	 
    // search users from API
    const searchUsersApi = async (user) => {
        const res = await getAllUserslistAction();
        if (res.success) {
            let users = res.data
            setUsers(users);

        } else {
            setUsers([]);
        }
    };

    const getselecteduserAddressApi = async (id) => {
        console.log(id)

        let res = await selecteduseraddressAction({ user_id: id })
        if (res.success) {
            let address = res.data
         
            const defaultAddr = address.find(address => address.shipto === 0 && address.set_default === 1);
            const defaultAddr2 = address.find(address => address.shipto === 1 && address.set_default === 1);
            const siteAddress = address.find(address => address.shipto === 2 && address.set_default === 1);
            if (defaultAddr) {
                setDefaultAddress(defaultAddr);
            }
            if (defaultAddr2) {
                setDefaultAddress2(defaultAddr2);
            }
            if (siteAddress) {
                setsiteDeliveryAdd(siteAddress);
            }
            
            setUserAddress(address)
          
        }
        else {
            setUserAddress([])
        }



    }
    const handleAddressChange = async (e) => {
		
		
		let res = await UpdateCartCustomFreightAction({
            cart_id: quote_id,
            address_id: showNewAddress,
            user_id: orderForm?.user_id,
            custom_freight: 0,
        });
        if (res.success) {
            //~ updateordersummery(quote_id)
            setTotals(res.data)
        }
		
		
		setshowNewAddress(Number(e.target.value)); 
		if(Number(e.target.value)==3)
		{		
			setnewAddressForm((old) => {
                
				return { ...old, 'shipto': 2 };
			});
		}else{
			setnewAddressForm((old) => {
				return { ...old, 'shipto': 1 };
			});
			
			//~ if(totals.postcodeType != 1 && totals.postcodeType != 2)
			//~ {
				//~ calculateorderShippingApi(quote_id)
			//~ }
		}
		
		
		
		
		
		
	  };
    // handle user selection
    const selectUserHandler = async (user) => {
        getselecteduserAddressApi(user.id)
        setOrderForm({
           
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone,
            company_name: user.company,
            orderProducts: [],
            user_id: user.id,
            tired_id: user.tiered_group
        });


    };
    // create quote
    const CreateUserOrderQuoteApi = async () => {
        let res = await CreateUserOrderQuoteAction({ user_id: orderForm.user_id, company: orderForm.company_name })
        if (res.success) {
			let quoteDetails =res.data;
            toast.success(res.msg);
            Setquote_id(quoteDetails.id)
            SetQuotecode(quoteDetails.quote_code);
        }
        else {
            toast.error(res.msg);
        }

    }

    // handle key press for phone number input
    const handleKeyPress = (e) => {
        const isValidInput = /^[0-9\b]+$/.test(e.key);
        if (!isValidInput) {
            e.preventDefault();
        }
    };

    // handle input values for form fields
    const inputHandler = (e) => {
        const { name, value } = e.target;
        setOrderForm((prev) => ({ ...prev, [name]: value }));
    };
    // search products from API
    const searchProductApi = async (str) => {
       if(quote_id ===''){
        toast.error("please select user")
       }
       else{
        if (str !== '') {
            const res = await searchorderproductAction({ str });
            if (res.success) {
                setSearchProduct(res.data);
            } else {
                setSearchProduct([]);
            }
        } else {
            setSearchProduct([]);
        }
    }
    }
  

    // remove product from the order
    const removeorderproduct = (part_number) => {
        setOrderForm((prevForm) => ({
            ...prevForm,
            orderProducts: prevForm.orderProducts.filter(product => product.part_number !== part_number)
        }));
    };
    // handle product selection
    const handleProductSelect = async (product) => {
      
        let res = await AddtoquoteCartAction({ product_id: product.id, hobson_part: product.hobson_part, user_id: orderForm?.user_id, cart_id: quote_id })
        updateordersummery()
        if (res.success) {
          
            setorderProducts(res.data)
        }

        else {

        }
        setSearchProduct([]);
    };
   
    const updateordersummery = async (c) => {
        let res = await getquoteOrderTotalAction({ cart_id: quote_id, address_id: showNewAddress, user_id: orderForm?.user_id });
        if (res.success) {
            setTotals(res.data);
            if(res.data.postcodeType != 1 && res.data.postcodeType != 2)
            {
				calculateorderShippingApi(quote_id)
			}
        }

    }
   

    const calculateorderShippingApi = async (cart_id) => {
        let res = await calculateorderShippingAction({ cart_id, address_id: showNewAddress,user_id:orderForm.user_id });
        if (res.success) {
            setTotals(res.data);
        } else {
            toast.error(res.msg);
        }

    }
    
  const formatToTwoDecimals = (value) => {
    if (!value) return "";

    let strValue = String(value); // Ensure value is treated as a string

    // Allow input like '3.'
    if (strValue.endsWith('.')) {
        return strValue;
    }

    let num = parseFloat(strValue);
    
    if (!isNaN(num)) {
        return num.toFixed(2); // Format to 2 decimal places
    }

    return strValue; // Return original value if parsing fails
};



	const formatToTwoDecimals_new = (value) => {
    if (!value) return "";

    let strValue = String(value); // Ensure value is treated as a string

    // Allow input like '3.'
    if (strValue.endsWith('.')) {
        return strValue;
    }

    let num = parseFloat(strValue);
    
    if (!isNaN(num)) {
        // Check if the number has more than two decimal places
        const decimalPlaces = (strValue.split('.')[1] || '').length;
        if (decimalPlaces > 2) {
            return num.toFixed(2); // Format to 2 decimal places if more than two decimals
        }
        return strValue; // Return original value if not more than two decimals
    }

    return strValue; // Return original value if parsing fails
};




   
    // handle form submission
    const PlaceCODOrderApi = async(e) => {
		
		let res = await PlaceCODOrderAction({cart_id:quote_id,user_id:orderForm.user_id,address_id: showNewAddress,totals:totals})
        if(res.success){
            toast.success(res.msg)
            
            setTimeout(() => {
				window.location.href = `${config.baseUrl}orders`;
			}, 1200);
            
        }
        else{
            toast.error(res.msg)
        }
    };
     const increaseProductQty = async (cart_id, product_id) => {

        let res = await updatecartqytAction({ cart_id: cart_id, product_id: product_id, user_id: orderForm.user_id, action: "inc" })
        if (res.success) {
            updateordersummery(cart_id)
            setorderProducts(res.data)
        }
        else {

        }
    }
    const decreaseProductQty = async (cart_id, product_id) => {

        let res = await updatecartqytAction({ cart_id: cart_id, product_id: product_id, user_id: orderForm.user_id, action: "dec" })
        if (res.success) {
            updateordersummery(cart_id)
            setorderProducts(res.data)
        }
        else {

        }
    }
    const handleProductQuantityChange = async (e, cart_id, product_id) => {
        const newQuantity = parseInt(e.target.value);
        // Update the quantity in your local state here
        setorderProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.cart_quote_id === cart_id && product.product_id === product_id
                    ? { ...product, product_quantity: newQuantity }
                    : product
            )
        );
       
    };
	
	const updateProductQuantity = async (e,row) => {
        // console.log(row)
        let enteredQuantity = parseInt(e.target.value, 10);
        if (isNaN(enteredQuantity) || enteredQuantity < 0) {
            enteredQuantity = 0;
        }

        const cart_id= row.cart_quote_id
        const product_id= row.product_id
         const quantityBreak = row.sell_quantity_increment;
        //  console.log(quantityBreak)
         const updatedQuantity = Math.ceil(enteredQuantity / quantityBreak) * quantityBreak;      
        
         let productId =row.product_id;  
         
         setorderProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.cart_quote_id === cart_id && product.product_id === product_id
                    ? { ...product, product_quantity: updatedQuantity }
                    : product
            )
        );

         let res = await UpdateCartProductQtyAction({
            cart_id: cart_id,
            product_id:productId,
            address_id: showNewAddress,
            user_id: orderForm?.user_id,
            qty: updatedQuantity,
        });
        if (res.success) {
            updateordersummery(cart_id)
            setTotals(res.data)
        }

    };
    const updateProductQuantityOnEnter = async (e,row) => {
        if (e.key === 'Enter') {
            let enteredQuantity = parseInt(e.target.value, 10);
            if (isNaN(enteredQuantity) || enteredQuantity < 0) {
                enteredQuantity = 0;
            }
            const cart_id= row.cart_quote_id
            const product_id= row.product_id
                 const quantityBreak = row.sell_quantity_increment;
                 console.log(quantityBreak)
                 const updatedQuantity = Math.ceil(enteredQuantity / quantityBreak) * quantityBreak;	  
                 console.log(updatedQuantity)
                let productId =row.product_id;  
                setorderProducts((prevProducts) =>
                    prevProducts.map((product) =>
                        product.cart_quote_id === cart_id && product.product_id === product_id
                            ? { ...product, product_quantity: updatedQuantity }
                            : product
                    )
                );

            
         let res = await UpdateCartProductQtyAction({
            cart_id: quote_id,
            product_id:productId,
            address_id: showNewAddress,
            user_id: orderForm?.user_id,
            qty: updatedQuantity,
        });
        if (res.success) {
            updateordersummery(cart_id)
            setTotals(res.data)
        }
        }
    };
    
	const handleProductPriceChange = async (e, cart_id, product_id) => {
    let enteredPrice = e.target.value;

    const floatPattern = /^(\d+(\.\d{0,2})?)?$/; 

    if (floatPattern.test(enteredPrice)) {
        setorderProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.cart_quote_id === cart_id && product.product_id === product_id
                    ? { ...product, custom_price: enteredPrice }
                    : product
            )
        );
    } else {
        console.log("Invalid price input, please enter a valid float value.");
    }

    console.log(enteredPrice); // Log entered price for debugging
};


	const updateProductPrice = async (e,row) => {
        // console.log(row)
        let enteredPrice = parseFloat(e.target.value);
        if (isNaN(enteredPrice) || enteredPrice < 0) {
            enteredPrice = 0;
        }

        const cart_id= row.cart_quote_id
        const product_id= row.product_id
        
         let productId =row.product_id;  
         
         setorderProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.cart_quote_id === cart_id && product.product_id === product_id
                    ? { ...product, custom_price: enteredPrice }
                    : product
            )
        );

         let res = await UpdateCartProductPriceAction({
            cart_id: cart_id,
            product_id:productId,
            address_id: showNewAddress,
            user_id: orderForm?.user_id,
            custom_price: enteredPrice,
        });
        if (res.success) {
            updateordersummery(cart_id)
            setorderProducts(res.data)
        }

    };
    const updateProductPriceOnEnter = async (e,row) => {
        if (e.key === 'Enter') {
            let enteredPrice = parseFloat(e.target.value);
            if (isNaN(enteredPrice) || enteredPrice < 0) {
                enteredPrice = 0;
            }
            const cart_id= row.cart_quote_id
            const product_id= row.product_id
                let productId =row.product_id;  
                setorderProducts((prevProducts) =>
                    prevProducts.map((product) =>
                        product.cart_quote_id === cart_id && product.product_id === product_id
                            ? { ...product, custom_price: enteredPrice }
                            : product
                    )
                );

            
         let res = await UpdateCartProductPriceAction({
            cart_id: quote_id,
            product_id:productId,
            address_id: showNewAddress,
            user_id: orderForm?.user_id,
            custom_price: enteredPrice,
        });
        if (res.success) {
            updateordersummery(cart_id)
            setorderProducts(res.data)
        }
        }
        
        
    };
    
    
	const handleCustomFreightChange = async (e) => {
		//~ let custom_freight = parseFloat(e.target.value);
		let custom_freight = e.target.value;
		
		const floatPattern = /^(\d+(\.\d{0,2})?)?$/; 

		if (floatPattern.test(custom_freight)) {
		setTotals((prevTotals) => ({
			...prevTotals,
			delivery: custom_freight,
		}));
		} else {
        console.log("Invalid custom freight input, please enter a valid float value.");
    }

    console.log(custom_freight); // Log entered custom freight for debugging
				
	};

	 
	const updateCustomFreight = async (e) => {
       
        let custom_freight = parseFloat(e.target.value);
        if (isNaN(custom_freight) || custom_freight < 0) {
            custom_freight = 0;
        }

        let res = await UpdateCartCustomFreightAction({
            cart_id: quote_id,
            address_id: showNewAddress,
            user_id: orderForm?.user_id,
            custom_freight: custom_freight,
        });
        if (res.success) {
            updateordersummery(quote_id)
        }

    };
    const updateCustomFreightOnEnter = async (e) => {
        if (e.key === 'Enter') {
            let custom_freight = parseFloat(e.target.value);
            if (isNaN(custom_freight) || custom_freight < 0) {
                custom_freight = 0;
            }
          
          
         let res = await UpdateCartCustomFreightAction({
            cart_id: quote_id,
            address_id: showNewAddress,
            user_id: orderForm?.user_id,
            custom_freight: custom_freight,
        });
        if (res.success) {
            updateordersummery(quote_id)
        }
        }
    };
    
    
	const addclosebutton = async () => {
        setAddModalopen(0);        
    }
    const openclosebutton = async () => {
        setAddModalopen(1); 
        setshowAddressEditForm(0);       
    }
    
    
    
     // remove product from the order
    const CartRemoveProduct = async (cart_id, product_id) => {

        Swal.fire({
            title: '',
            text: "Are you sure you want to remove this product?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removecartProductAction({ product_id: product_id, cart_id: cart_id, user_id: orderForm.user_id });
                if (res.success) {
                    updateordersummery(cart_id)
                    setorderProducts(res.data)
                } else {
                    toast.error(res.msg);
                }
            }
        });

    }
    
    return (
        <>
            <Sidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <Toaster />
                <Header />
                <div className="container-lg mb-4">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb my-0 ms-2">
                            <li className="breadcrumb-item">
                                <Link className="homelink" to={`${config.baseUrl}`}>Home</Link>
                            </li>
                            <li className="breadcrumb-item active"><span>Create order quote
                            </span></li>
                        </ol>
                    </nav>
                </div>
                <div className="body flex-grow-1 px-3">
                    <div className="container-lg">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col-md-6 text-start">
                                                <span>Create order {quotecode == '' ? "" : `#${quotecode}`}</span>
                                            </div>
                                            <div className="col-md-6 text-end">
                                                <Link className="btn btn-primary editbtn text-white" to={`${config.baseUrl}orders`}>Back</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body inner-body">
                                        <form className="row g-3">
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="Users">Users<span className="req-star">*</span></label>
                                                {quote_id == '' ?
                                                    <select
                                                        className={validationError.Users_Error ? 'form-control is-invalid' : 'form-control'}
                                                        id="Users"
                                                        name="Users"
                                                        onChange={(e) => selectUserHandler(users.find(user => user.id == e.target.value))}
                                                    >
                                                        <option value="">Select User</option>
                                                        {users.map((user) => (
                                                            <option key={user.id} value={user.id}>
                                                                {user.first_name} {user.last_name} ({user.email})
                                                            </option>
                                                        ))}
                                                    </select> :
                                                    <select
                                                        className={validationError.Users_Error ? 'form-control is-invalid' : 'form-control'}
                                                        id="Users"
                                                        name="Users"
                                                        onChange={(e) => selectUserHandler(users.find(user => user.id == e.target.value))}
                                                        disabled
                                                    >
                                                        <option value="">Select User</option>
                                                        {users.map((user) => (
                                                            <option key={user.id} value={user.id}>
                                                                {user.first_name} {user.last_name}  ({user.email})
                                                            </option>
                                                        ))}
                                                    </select>
                                                }


                                            </div>
                                            <div className="invalid-feedback">{validationError.Users_Error}</div>

                                            <div className="col-md-6 mt-5">
                                                {quote_id == '' ?
                                                    <a className="btn btn-primary createbtn" onClick={CreateUserOrderQuoteApi}
                                                    ><i className="fa fa-plus" ></i>  Create Quotes</a> :
                                                    <a className="btn btn-primary createbtn"
                                                    ><i className="fa fa-plus" ></i>  Create Quotes</a>}
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="orderProducts">Order Products<span className="req-star">*</span></label>
                                                <div className="order-product-search">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search Products"
                                                        onChange={(e) => searchProductApi(e.target.value)}
                                                    />
                                                    {searchProduct.length > 0 && (
                                                        <ul className="suburbList">
                                                            {searchProduct.map((row) => (
                                                                <li key={row.id} onClick={() => handleProductSelect(row)}>
                                                                    {row.hobson_part}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                                <div className="invalid-feedback">{validationError.orderProductsError}</div>
                                            </div>
                                          
                                        </form>
                                        {orderProducts.length>0 && (
                                            <div className="row" id="tempproducts">
                                                <table className="table mb-0">
                                                    <thead className="table-light fw-semibold">
                                                        <tr className="align-middle table-heading">
                                                            <th>Name</th>
                                                            <th>Description</th>
                                                            <th>Custom Price</th>
                                                            <th>Price</th>
                                                            <th style={{textAlign:"center"}}>Quantity</th>
                                                            <th>SubTotal</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderProducts.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{row.product_sku}</td>
                                                                <td>{row.product_description}</td>
                                                                <td> 
                                                                <input 
                                                                        type="text" 
                                                                        className="form-control" 
                                                                        style={{height: "25px", width: "80px", textAlign: "center"}} 
                                                                        value={row.custom_price && row.custom_price !== "" ? formatToTwoDecimals_new(row.custom_price) : row.custom_price} 
                                                                        onChange={(e) => handleProductPriceChange(e, row.cart_quote_id, row.product_id)} 
                                                                        onBlur={(e) => updateProductPrice(e, row)}  
                                                                        onKeyDown={(e) => updateProductPriceOnEnter(e, row)} 
                                                                    />
                                                                
                                                               </td>
                                                                <td>
                                                                 ${formatToTwoDecimals(row.display_product_price)} / {row.price_per} </td>
                                                                <td style={{textAlign:"center"}}>
																<div className="quntity-box d-flex gap-3 items-center ">
																	<div className="value-button mt-1 csr-ptr" id="decrease" onClick={() => decreaseProductQty(row.cart_quote_id, row.product_id)} >-</div>
																	<input type="text" className="form-control" style={{height:"25px",width:"80px",textAlign:"center"}} value={row.product_quantity} onChange={(e) => handleProductQuantityChange(e, row.cart_quote_id, row.product_id)} onBlur={(e)=>updateProductQuantity(e,row)}  onKeyDown={(e) => updateProductQuantityOnEnter(e,row)}/>
																	<div className="csr-ptr mt-1 " id="increase" onClick={() => increaseProductQty(row.cart_quote_id, row.product_id)} >+</div>
																</div>
																</td>
                                                                <td>{(row.product_per_price * row.product_quantity).toFixed(2)}</td>
                                                               <td>
                                                                    <span className="csr-ptr" onClick={() => CartRemoveProduct(row.cart_quote_id, row.product_id)}>x</span>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        {orderProducts.length>0 ?
                                            <div className="delivery-method mt-3">
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <div className="row">
                                                            <div className="col-md-4 d-none">
                                                                <div className="deliver-block">
                                                                    <h4>DELIVERY METHOD</h4>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label" htmlFor="shipmethod-CMM0016-2">
                                                                            <input type="radio" className="form-check-input shipmethod" defaultChecked />
                                                                            Delivered
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="deliver-section">
                                                                    <h4>DELIVERY ADDRESS</h4>
                                                                    <div className="deliver-detail d-flex">
                                                                        <div className="pickup">
                                                                            <form>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label">
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="form-check-input"
                                                                                            name="opt"
                                                                                            value="1"
                                                                                            checked={showNewAddress === 1}
                                                                                            onChange={handleAddressChange}
                                                                                        />
                                                                                        Ship to
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label">
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="form-check-input"
                                                                                            name="opt"
                                                                                            value="2"
                                                                                            checked={showNewAddress === 2}
                                                                                            onChange={handleAddressChange}
                                                                                        />
                                                                                        New Address
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label">
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="form-check-input"
                                                                                            name="opt"
                                                                                            value="3"
                                                                                            checked={showNewAddress === 3}
                                                                                            onChange={handleAddressChange}
                                                                                        />
                                                                                        Site Delivery
                                                                                    </label>
                                                                                </div>

                                                                            </form>
                                                                        </div>
                                                                        <div className="delicer-address" style={{ marginLeft: "150px" }}>
                                                                            {showNewAddress == 1 ? (
                                                                                <>
                                                                                    <p className="mb-0">
                                                                                        {defaultAddress?.address_1}<br />
                                                                                        {defaultAddress?.city}&nbsp;{defaultAddress?.state}&nbsp;{defaultAddress?.postcode}
                                                                                    </p>
                                                                                </>
                                                                            ) : showNewAddress == 2 ?
                                                                                (<>
                                                                                    {defaultAddress2?.address_1}<br />
                                                                                    {defaultAddress2?.city}&nbsp;{defaultAddress2?.state}&nbsp;{defaultAddress2?.postcode}
                                                                                    
                                                                                    <p><a href="javascript:;" onClick={openclosebutton}>{defaultAddress2.length > 0 ? 'Change Address' :'Add new address'}</a></p>


                                                                                </>) :
                                                                                (<>
                                                                                    {siteDeliveryAdd?.address_1}<br />
                                                                                    {siteDeliveryAdd?.city}&nbsp;{siteDeliveryAdd?.state}&nbsp;{siteDeliveryAdd?.postcode}
                                                                                    { }
                                                                                    
                                                                                    <p><a href="javascript:;" onClick={openclosebutton}>{siteDeliveryAdd.length > 0 ? 'Change Address' :'Add new address'}</a></p>
                                                                                    
                                                                                    
                                                                                </>)

                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="row ">
                                                            <h4>ORDER SUMMARY</h4>
                                                            <ol class="order-summary-ul">
                                                                <li className='mt-2' >
                                                                    <span className="subtotal">Subtotal</span>
                                                                    <span className="subtotal-prize">${totals ? totals.subtotal : 0.00}</span>
                                                                </li>
                                                                {totals.postcodeType == 1 || totals.postcodeType == 2 ? (
                                                                    <>
                                                                        <li className='mt-2' >
                                                                            <span className="subtotal">Delivery</span>
                                                                            
                                                                            
                                                                            <span className="subtotal-prize"><input type="text" className="form-control" style={{height:"25px",width:"80px",textAlign:"center"}} value={totals ? totals.delivery : 0.00} onChange={(e) => handleCustomFreightChange(e)} onBlur={(e)=>updateCustomFreight(e)}  onKeyDown={(e) => updateCustomFreightOnEnter(e)} /></span>
                                                                            
                                                                            
                                                                            
                                                                            <span className="subtotal-prize d-none">${totals ? totals.delivery : 0.00}</span>
                                                                        </li>
                                                                    </>
                                                                ) :
                                                                    (
                                                                        <>
                                                                           
                                                                            <li className='mt-2' >
                                                                                <span className="subtotal"><a href="javascript:;" onClick={() => calculateorderShippingApi(totals.cart_id)}>Click here</a> to calculate shipping.</span>

                                                                            </li>
                                                                        </>
                                                                    )
                                                                }
                                                                <li className='mt-2' >
                                                                    <span className="subtotal">GST</span>
                                                                    <span className="subtotal-prize" >${totals ? totals.gstontotal : 0.00}</span>
                                                                </li>
                                                                <li className='mt-2' >
                                                                    <span className="subtotal"><b>Total</b></span>
                                                                    <span className="subtotal-prize"><b>${totals ? totals.total : 0.00}</b></span>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''}

                                            {orderProducts.length >0 ?
                                            <div className="col-md-12 text-end">
                                                <button type="submit" className="btn btn-primary" onClick={PlaceCODOrderApi} >Order Now</button>
                                            </div> : ""}
                                        
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                
                
                
                
                
                
                <div id="dorshipaddress" className={addmodalopen == '0' ? "modal fade cart-modal mc-popup dorshipaddress" : "dorshipaddress mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgb(255 255 255 / 61%)', display: addmodalopen == '0' ? 'none' : 'block' }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                     <div className="modal-header">
						<h5 className="modal-title" id="exampleModalLongTitle">ADDRESSES</h5>
						<button type="button" onClick={addclosebutton} className="close btnClose" data-dismiss="modal" aria-label="Close">
						  <span aria-hidden="true">&times;</span>
						</button>
					  </div>						                                     
                         <div className="modal-body">						 							
								 {
									 UserAddress.length > 0 ? (
									  <> 
										<div className="row">
										
										  { showNewAddress==3 ? (
											  UserAddress.filter(usr_adr => usr_adr.shipto === 2).map(usr_adr => (
											<> 
											  <div className="col-md-6" key={usr_adr.id}>
												<div className={usr_adr.set_default === 1 ? 'address-item address-block default_address' : 'address-item address-block'}>
												  <div title="Select this Address" className="address" onClick={() => updateShipToAddress(usr_adr)}>
													{showNewAddress==3 ? 
														(
														<>
														<p>{usr_adr.first_name} &nbsp;{usr_adr.last_name}</p>
														<p>{usr_adr.phone}</p>
														</>
														):null
													}
													<p>{usr_adr.address_1}</p>													
													<p>{usr_adr.city} {usr_adr.state}, {usr_adr.postcode}</p> 
												  </div>
												  <div className="linkswrapper">
													
												  </div>
												</div>	
											  </div>
											</>
										  ))
										  ) :
										  (
											  UserAddress.filter(usr_adr => usr_adr.shipto === 1).map(usr_adr => (
											<> 
											  <div className="col-md-6" key={usr_adr.id}>
												<div className={usr_adr.set_default === 1 ? 'address-item address-block default_address' : 'address-item address-block'}>
												  <div title="Select this Address" className="address" onClick={() => updateShipToAddress(usr_adr)}>
													{showNewAddress==3 ? 
														(
														<>
														<p>{usr_adr.first_name} &nbsp;{usr_adr.last_name}</p>
														<p>{usr_adr.phone}</p>
														</>
														):null
													}
													<p>{usr_adr.address_1}</p>													
													<p>{usr_adr.city} {usr_adr.state}, {usr_adr.postcode}</p> 
												  </div>
												  <div className="linkswrapper">
													
												  </div>
												</div>	
											  </div>
											</>
										  ))
										  )
										  
										  }
										</div>															
									  </>
									) : null 
																		
									}									
														  
						  </div>
                    </div>
                </div>
            </div> 
                
                
                
                
                
                
                
                
                
                <Footer />
            </div>
        </>
    );
};

export default Createorder;
