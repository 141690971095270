import React, { useEffect, useState } from 'react';
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import Header from '../directives/header';
import Swal from 'sweetalert2';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import { getTieredGrouplistAction, gettemproductAction,getQuotationDetailsAction,removequoteProductAction,updateQuoteDetailAction } from '../Action/action';
import Cookies from "js-cookie";
const loginData = (!Cookies.get('loginSuccessfastenerwarehouseAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessfastenerwarehouseAdmin'));
 const Editquote = () => {
	 if (!loginData || loginData == '') {
		window.location.href = `${config.baseUrl}`;
	}
	if(loginData.role != config.role_admin){
		window.location.href = `dashboard`;	
	}
    const {id} = useParams()
    const [quotesForm, setQuotesForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company: '',
        tiered_group: '',
        products: []
    });
    const [validationError, setValidationError] = useState({});
    const [tiered_group, settiered_group] = useState([]);
    const [searchProduct, setsearchProduct] = useState([]);
    const [spinloader, setspinloader] = useState('0');
    useEffect(() => {
        getQuotationDetails()
        getTieredGroupAPI();
    }, []);

    const handleKeyPress = (e) => {
        const isValidInput = /^[0-9\b]+$/.test(e.key);
        if (!isValidInput) {
            e.preventDefault();
        }
    };
    
   const handlePriceKeyPress = (e) => {
		const isValidInput = /^[0-9.\b]$/.test(e.key);
		if (!isValidInput) {
		  e.preventDefault();
		}
	  };


    const inputHandler = (e) => {
        const { name, value } = e.target;
        setQuotesForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    };
    
    const handleProductSelect = (row) => {  
        const { description, sell_quantity_minimum, id, price_per, hobson_part, sell_quantity_increment } = row;
        
        setQuotesForm((prevForm) => {
         
            const existingProduct = prevForm.products.find(product => product.product_id === id);
            
            if (existingProduct) {
              
                const updatedProducts = prevForm.products.map(product => 
                    product.product_id === id
                        ? { ...product, product_quantity: product.product_quantity + sell_quantity_increment }
                        : product
                );
                return { ...prevForm, products: updatedProducts };
            } else {
               
                return {
                    ...prevForm,
                    products: [
                        ...prevForm.products,
                        { 
                            product_description: description,
                            product_quantity: sell_quantity_minimum,
                            product_sku: hobson_part,
                            sell_quantity_increment,
                            product_id: id,
                            price_per: price_per,
                            custom_price: '' 
                        }
                    ]
                };
            }
        });
    
        setsearchProduct([]);
    };
    
    

    const getQuotationDetails = async () => {
		let res = await getQuotationDetailsAction({quote_id:id});
		if (res.success) {
			let data = res.data		
			setQuotesForm(data);	
			  
		}else{
			setQuotesForm({});			
		}
	}
    const getTieredGroupAPI = async () => {
        try {
            const res = await getTieredGrouplistAction();
            settiered_group(res.success ? res.data : []);
        } catch (error) {
            console.error('Error fetching tiered groups:', error);
            toast.error("Failed to fetch tiered groups.");
            settiered_group([]);
        }
    };

    const searchtempproduct = async (str) => {
        if (str !== '') {
            const res = await gettemproductAction({ str });
            if (res.success) {
                setsearchProduct(res.data);
               
            } else {
                setsearchProduct([]);
            }
        } else {
            setsearchProduct([]);
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!quotesForm.first_name) errors.first_nameError = "Please enter first name.";
        if (!quotesForm.last_name) errors.last_nameError = "Please enter last name.";
        if (!quotesForm.email) errors.emailError = "Please enter email.";
        if (!quotesForm.phone) errors.phoneError = "Please enter phone number.";
        if (!quotesForm.company) errors.company_nameError = "Please enter company name.";
        if (!quotesForm.tiered_group) errors.tiered_idError = "Tiered group is required.";
        if (!quotesForm.products.length) errors.quotesProductsError = 'Please select at least one product.';

        setValidationError(errors);
        return Object.keys(errors).length <= 0;
    };

   
// update quote detail
    const submitForm = async (e) => {
      
        e.preventDefault();
        console.log(quotesForm)
        const isValid = validateForm();
		if (!isValid) {
		}
        else{
			setspinloader(1);
            let res = await updateQuoteDetailAction({...quotesForm,quotation_id:id})
            if(res.success){
                toast.success(res.msg)
               setTimeout(() => {
					window.location.href = `${config.baseUrl}quotes`;
				}, 1200);
            }
            else{
				setspinloader(0);
                toast.error(res.msg)
            }
        }
    };

    // for handling product quantity change
    const handleProductQuantityChange = async (e,row,product_id) => {
      
        let newQuantity = parseInt(e.target.value);
        if(newQuantity > 0){}else {newQuantity=0;}        
        setQuotesForm((prevForm) => ({
            ...prevForm,
            products: prevForm.products.map((product) =>
                  product.product_id === product_id
                    ? { ...product, product_quantity: newQuantity }
                    : product
            ),
        }));

    };
  
    const handleQuantityChangeManual = (e, row,action) => {
        let enteredQuantity = parseInt(e.target.value,10);
        if (isNaN(enteredQuantity) || enteredQuantity < 0) {
            enteredQuantity = 0;
        }    
        let product_id = row.product_id;
        const quantityBreak = row.sell_quantity_increment;
    
        var updatedQuantity ;
        const currentQuantity = row.product_quantity || 0; 
        if(action == "inc") {
            
         updatedQuantity = currentQuantity + quantityBreak;
        }
        if(action == "dec"){
            if(currentQuantity <= 0){
                return currentQuantity
            }

            updatedQuantity = currentQuantity - quantityBreak;
        }
        setQuotesForm((prevForm) => ({
            ...prevForm,
            products: prevForm.products.map((product) =>
                product.product_id === product_id
                    ? { ...product, product_quantity: updatedQuantity }
                    : product
            ),
        }));
    };
    
    const updateProductQuantity = async (e,row) => {
     
        let enteredQuantity = parseInt(e.target.value, 10);
  
        if (isNaN(enteredQuantity) || enteredQuantity < 0) {
            enteredQuantity = 0;
        }
        const product_id= row.product_id
         const quantityBreak = row.sell_quantity_increment;
        console.log(product_id,quantityBreak)
         const updatedQuantity = Math.ceil(enteredQuantity / quantityBreak) * quantityBreak;  
        
         setQuotesForm((prevForm) => ({
            ...prevForm,
            products: prevForm.products.map((product) =>
                 product.product_id === product_id
                    ? { ...product, product_quantity: updatedQuantity }
                    : product
            ),
        }));

    };
    const updateProductQuantityOnEnter = async (e,row) => {
        if (e.key === 'Enter') {
            let enteredQuantity = parseInt(e.target.value, 10);
            if (isNaN(enteredQuantity) || enteredQuantity < 0) {
                enteredQuantity = 0;
            }
            const product_id= row.product_id
         const quantityBreak = row.sell_quantity_increment;
       
         const updatedQuantity = Math.ceil(enteredQuantity / quantityBreak) * quantityBreak;  
        
         setQuotesForm((prevForm) => ({
            ...prevForm,
            products: prevForm.products.map((product) =>
                 product.product_id === product_id
                    ? { ...product, product_quantity: updatedQuantity }
                    : product
            ),
        }));
        }
    };
    
     const handlePriceChange = (e, row) => {
		const newPrice = e.target.value;
		const sanitizedValue = newPrice.replace(/[^0-9.]/g, '');
		const decimalParts = sanitizedValue.split('.');
        const cleanedValue = decimalParts.length > 2 ? `${decimalParts[0]}.${decimalParts[1]}` : sanitizedValue;
		console.log(newPrice);
		setQuotesForm((prevForm) => ({
			...prevForm,
			products: prevForm.products.map((product) =>
				product.product_id === row.product_id
					? { ...product, custom_price: cleanedValue === '' ? '' : cleanedValue }
					: product
			),
		}));
	};

    
    const CartRemoveProduct = async (quote_id, product_id) => {
        console.log(quote_id, product_id);
        Swal.fire({
            title: '',
            text: "Are you sure you want to remove this product?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
        }).then(async (result) => {
           
            if (quote_id === undefined || quote_id === null) {
              
                setQuotesForm((prevForm) => ({
                    ...prevForm,
                    products: prevForm.products.filter(product => product.product_id !== product_id)
                }));
            } else {
               
                if (result.isConfirmed) {
                    let res = await removequoteProductAction({ product_id, quote_id });
                    if (res.success) {
                        setQuotesForm(res.data); 
                    } else {
                        toast.error(res.msg); 
                    }
                }
            }
        });
    };
   
    return (
        <>
            <Sidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <Toaster />
                <Header />
                <div className="container-lg mb-4">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb my-0 ms-2">
                            <li className="breadcrumb-item">
                                <Link className="homelink" to={`${config.baseUrl}`}>Home</Link>
                            </li>
                            <li className="breadcrumb-item active"><span>Edit quote</span></li>
                        </ol>
                    </nav>
                </div>
                <div className="body flex-grow-1 px-3">
                    <div className="container-lg">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col-md-6 text-start">
                                                <span>Edit quote</span>
                                            </div>
                                            <div className="col-md-6 text-end">
                                                <Link className="btn btn-primary editbtn text-white" to={`${config.baseUrl}quotes`}>Back</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body inner-body">
                                        <form className="row g-3">
                                        <div className="col-md-4">
                                                <label className="form-label" htmlFor="tiered_id">Tiered group<span className="req-star">*</span></label>
                                                <select className={validationError.tiered_groupError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="tiered_group" name="tiered_group" onChange={inputHandler} value={quotesForm?.tiered_group}>
															<option value="">Select tiered group</option>
															{tiered_group.length > 0 ? (
																tiered_group.map((tg) => (
																	<option key={tg.id} value={tg.id}>{tg.title}</option>
																))
															) : null}
														</select>
                                                <div className="invalid-feedback">{validationError.tiered_groupError}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="first_name">First name<span className="req-star">*</span></label>
                                                <input className={validationError.first_nameError ? 'form-control is-invalid' : 'form-control'} id="first_name" name="first_name" placeholder="Enter first name" type="text" onChange={inputHandler} value={quotesForm?.first_name} />
                                                <div className="invalid-feedback">{validationError.first_nameError}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="last_name">Last name<span className="req-star">*</span></label>
                                                <input className={validationError.last_nameError ? 'form-control is-invalid' : 'form-control'} id="last_name" name="last_name" placeholder="Enter last name" type="text" onChange={inputHandler} value={quotesForm?.last_name} />
                                                <div className="invalid-feedback">{validationError.last_nameError}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="company_name">Company name<span className="req-star">*</span></label>
                                                <input className={validationError.company_nameError ? 'form-control is-invalid' : 'form-control'} id="company_name" name="company_name" placeholder="Enter company name" type="text" onChange={inputHandler} value={quotesForm?.company} />
                                                <div className="invalid-feedback">{validationError.company_nameError}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="email">Email<span className="req-star">*</span></label>
                                                <input className={validationError.emailError ? 'form-control is-invalid' : 'form-control'} id="email" name="email" placeholder="Enter email address" type="email" onChange={inputHandler} value={quotesForm?.email} />
                                                <div className="invalid-feedback">{validationError.emailError}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="phone">Phone<span className="req-star">*</span></label>
                                                <input className={validationError.phoneError ? 'form-control is-invalid' : 'form-control'} id="phone" name="phone" placeholder="Enter phone number" type="text" onChange={inputHandler} onKeyPress={handleKeyPress} maxLength="10" value={quotesForm?.phone} />
                                                <div className="invalid-feedback">{validationError.phoneError}</div>
                                            </div>
                                            
                                           
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="quotesProducts">Search product <span className="req-star">*</span></label>
                                                <input
                                                    className={validationError.quotesProductsError ? 'form-control is-invalid' : 'form-control'}
                                                    name="quotesProducts"
                                                    placeholder="Search product"
                                                    type="text"
                                                    onChange={(e) => searchtempproduct(e.target.value)}
                                                    autoComplete="off"
                                                />
                                                {searchProduct.length > 0 && (
                                                    <ul className="suburbList">
                                                        {searchProduct.map((row) => (
                                                            <li key={row.id} onClick={() => handleProductSelect(row)}>
                                                                {row.hobson_part}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                                <div className="invalid-feedback">{validationError.quotesProductsError}</div>
                                            </div>
                                            {quotesForm.products.length>0 && (
                                                <div className="row" id="tempproducts">
                                                    <table className="table mb-0">
                                                        <thead className="table-light fw-semibold">
                                                            <tr className="align-middle table-heading">
                                                                <th>PART</th>
                                                                <th >Description</th>
                                                                <th>Custom Price</th>
                                                                <th style={{textAlign:"center"}}>Quantity</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {quotesForm.products.map((row, index) => (
                                                                <tr key={index}>
                                                                    <td>{row.product_sku}</td>
                                                                    <td>{row.product_description}</td>
                                                                    <td>
																		<input 
																			type="text" 
																			className="form-control" 
																			value={row.custom_price} 
																			onChange={(e) => handlePriceChange(e, row)}
																			style={{height:"25px",width:"80px"}}
																			onKeyPress={handlePriceKeyPress}
																		/>
																	</td>
                                                                    <td style={{textAlign:"center"}}>
																<div className="quntity-box d-flex gap-3 items-center ">
																	<div className="value-button mt-1 csr-ptr" id="decrease" onClick={(e)=>handleQuantityChangeManual(e,row,'dec')}  >-</div>
																	<input type="text" className="form-control" style={{height:"25px",width:"80px",textAlign:"center"}} value={row.product_quantity}  onChange={(e) => handleProductQuantityChange(e, row, row.product_id)} onBlur={(e)=>updateProductQuantity(e,row)} onKeyDown={(e) => updateProductQuantityOnEnter(e,row)} />
																	<div className="csr-ptr mt-1 " id="increase" onClick={(e)=>handleQuantityChangeManual(e,row,'inc')}  >+</div>
																</div>
																</td>
                                                                    <td>
                                                                        <span className="csr-ptr" onClick={() => CartRemoveProduct(row.quotation_id, row.product_id)}>x</span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                            {quotesForm.products?  <div className="col-12 text-end">
                                                <button className="btn btn-primary" type="button" onClick={submitForm}>{spinloader==0 ? 'Update Quote' : 'Update...'}</button>
                                            </div>:''}
                                           
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Editquote
